// Features.js

import React from 'react';
import './Features.css';

import 'font-awesome/css/font-awesome.min.css';


function Features() {
    return (
        <div className="features">
            <h2>Features</h2>
            <div className="feature-item">
                <i className="fa fa-map" aria-hidden="true"></i>
                <b>Diverse Arenas</b>
                <p>From beginners to pros, there's an arena for everyone. Choose one that fits your skill and staking preference.</p>
            </div>
            <div className="feature-item">
                <i className="fa fa-gamepad" aria-hidden="true"></i>
                <b>Strategic Gameplay</b>
                <p>It's not just about quick reflexes. Strategic gameplay means every player has an equal shot at victory.</p>
            </div>
            <div className="feature-item">
                <i className="fa fa-trophy" aria-hidden="true"></i>
                <b>Engaging Prize Dynamics</b>
                <p>Arena Jackpots, top score prizes, special events, and more. New challenges and rewards await every time.</p>
            </div>
            <div className="feature-item">
                <i className="fa fa-dollar" aria-hidden="true"></i>
                <b>Transparent Tokenomics</b>
                <p>A clear breakdown of how $ARENA tokens flow within the ecosystem, ensuring fairness and value for players and investors.</p>
            </div>
        </div>
    );
}

export default Features;
