import React from 'react';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import HowItWorks from './components/HowItWorks';
import Features from './components/Features';
import Jackpot from './components/Jackpot';
import Tokenomics from './components/Tokenomics';
import StartPlaying from './components/StartPlaying';
import Footer from './components/Footer';
import styled from 'styled-components';

const AppContainer = styled.div`
  font-family: 'Arial', sans-serif;
`;

function App() {
  return (
    <AppContainer>
      <Header />
      <HeroSection />
      <HowItWorks />
      <Jackpot />
      {/* <Features /> */}
      <Tokenomics />
      <StartPlaying />
      <Footer />
    </AppContainer>
  );
}

export default App;
