// HeroSection.js


import './HeroNew.css';

import React, { Component } from 'react';

class HeroSection extends Component {
    componentDidMount() {
        const heroSection = document.querySelector('.heroSection');

        if (heroSection) {
            heroSection.addEventListener('mousemove', (e) => {
                const offsetX = -0.5 + e.clientX / window.innerWidth;
                const offsetY = -0.5 + e.clientY / window.innerHeight;

                heroSection.style.backgroundPosition = `
      calc(50% + ${offsetX * 50}px) 
      calc(50% + ${offsetY * 50}px)`;
            });
        }

    }

    render() {
        return (
            <div className="hero">
                <video autoPlay playsInline muted loop id="heroVideo" className="hero-video">
                    <source src="/gameplay.mp4" type="video/mp4"></source>
                    Your browser does not support the video tag.
                </video>

                <div className="hero-content">
                    <h1>Welcome to CoinArena!</h1>
                    <p>Dive into the exhilarating world of CoinArena where strategy meets opportunity in real-time crypto battles. Race against time, compete with peers, and stand a chance to hit the jackpot in our diverse arenas.</p>
                    <a href="https://app.coincombat.online" target="_blank" rel="noopener noreferrer">
                        <button className="hero-button">Enter the Arena</button>
                    </a>

                </div>
            </div>
        );
    }
}




export default HeroSection;
