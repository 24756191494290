// HowItWorks.js

import React from 'react';
import './HowItWorks.css'

function HowItWorks() {
    return (
        <div className="how-it-works" id="how-it-works">
            <h2>How It Works</h2>
            <div className="steps-container">
                <div className="step">
                    <i className="fa fa-sign-in" aria-hidden="true"></i>
                    <h3>Join the Battle</h3>
                    <p>Deposit $ARENA tokens and enter themed arenas, from the bustling "Penny Plaza" to the high-adrenaline "Diamond Dome."</p>
                </div>
                <div className="step">
                    <i className="fa fa-rocket" aria-hidden="true"></i>
                    <h3>Race & Strategize</h3>
                    <p>Every round is about skill and strategy. Zip around, collect tokens, and outsmart your opponents.</p>
                </div>
                {/* <div className="step">
                    <i className="fa fa-users" aria-hidden="true"></i>
                    <h3>Social & Teams</h3>
                    <p>Showcase your NFT avatars, collaborate with peers, and make new connections.</p>
                </div> */}
                <div className="step">
                    <i className="fa fa-trophy" aria-hidden="true"></i>
                    <h3>Big Wins Await</h3>
                    <p>Beyond the standard round winnings, players have multiple opportunities to clinch big prizes with our unique prize dynamics.</p>
                </div>
            </div>
        </div>

    );
}

export default HowItWorks;
