import React from 'react';
import './StartPlaying.css'; // Your CSS import

function StartPlaying() {
    return (
        <div className="start-playing">
            <h2>Ready to Battle?</h2>
            <p>Dive into the thrilling world of CoinArena and claim your glory!</p>
            <img src="helmet.png" alt="Gladiator Helmet" className="gladiator-helmet" />
            <a href="https://app.coincombat.online" target="_blank" rel="noopener noreferrer">
                <button className="enter-arena-btn">Enter the Arena</button>
            </a>

        </div>
    );
}

export default StartPlaying;