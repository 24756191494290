import styled from 'styled-components';

const JackpotContainer = styled.div`
  background: linear-gradient(to bottom, #1e2a38, #0a141f);
  padding: 80px 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
`;

const ChestGraphic = styled.img`
  width: 300px; /* adjust as needed */
  /* Optional shimmer animation */
  animation: shimmer 2s infinite;
  @keyframes shimmer {
    0% { filter: brightness(95%); }
    50% { filter: brightness(105%); }
    100% { filter: brightness(95%); }
  }
`;

const JackpotInfo = styled.div`
  text-align: left;
  max-width: 60%; /* adjust as needed */
  
  h2 {
    color: #ffd700;
    font-size: 32px;
  }
  
  p {
    color: #a1a9b4;
    margin-top: 20px;
    font-size: 18px;
  }
`;

const CTAButton = styled.a`
  display: inline-block;
  margin-top: 30px;
  padding: 10px 20px;
  color: #1e2a38;
  background-color: #ffd700;
  border-radius: 5px;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: #ffaa00;
    color: #1e2a38;
  }
`;

const Jackpot = () => {
  return (
    <JackpotContainer id="the-jackpot">
      <ChestGraphic src="jackpot.png" alt="Golden Jackpot Chest" />
      <JackpotInfo>
        <h2>The Ultimate Jackpot Awaits!</h2>
        <p>1% of all transaction fees + 1% of all game fees fuel the jackpot.</p>
        <p>With every round, there's a 1% chance of a jackpot chest drop.</p>
        <CTAButton href="#play">Try Your Luck Now!</CTAButton>
      </JackpotInfo>
    </JackpotContainer>
  );
}

export default Jackpot;
