// Footer.js

import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <footer>
            <div className="footer-container">
                <div className="footer-links">
                    <h4>Quick Links</h4>
                    <a href="#">Twitter</a>
                    <a href="#">Discord</a>
                    <a href="#">Telegram</a>
                    <a href="#">Uniswap</a>
                </div>
                {/* <div className="social-media-links">
                    <h4>Follow Us</h4>
                    <a href="link_to_discord"><i className="fa fa-discord"></i></a>
                    <a href="link_to_twitter"><i className="fa fa-twitter"></i></a>
                    <a href="link_to_dextools"><i className="fa fa-toolbox"></i></a>
                    <a href="link_to_uniswap"><i className="fa fa-exchange"></i></a>
                </div> */}
                <div className="copyright">
                    <p>&copy; 2023 Coin Combat</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
