import styled from 'styled-components';

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center; /* Vertically align items to the center */
  padding: 20px 5%; /* Use percentage for horizontal padding to maintain consistency across devices */
  background-color: #1e2a38; /* Slightly different shade for the dark theme */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for depth */
`;

const Logo = styled.h1`
  color: #ffffff;
  font-size: 28px;
  font-weight: 700; /* Bolder font for the logo */
`;

const NavLinks = styled.nav`
  a {
    margin: 0 15px;
    color: #a1a9b4; /* Slightly muted color for the links */
    text-decoration: none;
    font-weight: 500; /* Medium font weight */
    transition: color 0.3s ease; /* Transition for hover effect */

    &:hover {
      color: #ffffff; /* Bright white on hover */
    }
  }
`;

const Header = () => {
  return (
    <HeaderContainer>
      <Logo>CoinCombat</Logo>
      <NavLinks>
        <a href="#how-it-works">Game Overview</a>
        <a href="#the-jackpot">The Jackpot</a>
        <a href="#tokenomics">Tokenomics</a>
        <a href="https://app.coincombat.online">Start Playing</a>
      </NavLinks>
    </HeaderContainer>
  );
}

export default Header;
