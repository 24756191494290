import React from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import './Tokenomics.css';  // assuming you save the CSS in a separate file
import 'font-awesome/css/font-awesome.min.css';


const data = [
    { name: 'Initial LP', value: 80 },
    { name: 'Marketing Wallet', value: 10 },
    { name: 'Jackpot Fund', value: 10 },
];

const COLORS = ['#f39c12', '#2980b9', '#2ecc71'];

const buySellData = [
    { name: 'Liquidity Pool', value: 2 },
    { name: 'Developer & Gas Fees', value: 2 },
    { name: 'Revenue Sharing', value: 1 },
];

const gameplayData = [
    { name: 'Arena Jackpot', value: 1 },
    { name: 'Developer Compensation', value: 1 },
    { name: 'Token Burn', value: 1 },
];

const COLORS_2 = ['#0088FE', '#00C49F', '#FFBB28'];

function Tokenomics() {
    return (
        <div className="tokenomics" id="tokenomics">
            <h2>Tokenomics</h2>

            <div className="distribution">
                <h3>Initial Token Distribution</h3>
                <div className="chart-container">   {/* <-- New container */}
                    <PieChart width={400} height={400}>
                        <Pie
                            data={data}
                            cx={200}
                            cy={200}
                            outerRadius={100}
                            fill="#8884d8"
                            dataKey="value"
                        >
                            {
                                data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                            }
                        </Pie>
                        <Tooltip />
                    </PieChart>
                    <ul className="legend">    {/* <-- Added a class for specificity */}
                        <li><span className="color-box lp"></span> 80% Initial LP</li>
                        <li><span className="color-box marketing"></span> 10% Marketing Wallet</li>
                        <li><span className="color-box jackpot"></span> 10% Jackpot Fund</li>
                    </ul>
                </div>
            </div>

            <div className="fees-section">
                <h2>Understanding Our Fee Structure</h2>
                <p>Transparent fees designed with our community in mind.</p>

                <div className="fees-wrapper">
                    <div className="fee-container">
                        <h3>Transaction Fees: Buy & Sell</h3>
                        <PieChart width={400} height={300}>
                            <Pie
                                data={buySellData}
                                cx={200}
                                cy={150}
                                startAngle={180}
                                endAngle={0}
                                innerRadius={60}
                                outerRadius={100}
                                fill="#8884d8"
                                dataKey="value"
                            >
                                {buySellData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index]} />
                                ))}
                            </Pie>
                            <Tooltip content={<CustomTooltip />} />
                        </PieChart>
                        <div className="chart-container">
                            {/* Placeholder for your chart library */}
                            <i className="fa fa-tint" aria-hidden="true"></i> 2% Liquidity Pool
                            <i className="fa fa-wrench" aria-hidden="true"></i> 2% Developer & Gas Fees
                            <i className="fa fa-users" aria-hidden="true"></i> 1% Revenue Sharing
                        </div>
                    </div>

                    <div className="fee-container">
                        <h3>Game Round Fees: Making Every Round Count</h3>
                        <PieChart width={400} height={300}>
                            <Pie
                                data={gameplayData}
                                cx={200}
                                cy={150}
                                startAngle={180}
                                endAngle={0}
                                innerRadius={60}
                                outerRadius={100}
                                fill="#8884d8"
                                dataKey="value"
                            >
                                {gameplayData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index]} />
                                ))}
                            </Pie>
                            <Tooltip content={<CustomTooltip />} />
                        </PieChart>
                        <div className="chart-container">
                            {/* Placeholder for your chart library */}
                            <i className="fa fa-gift" aria-hidden="true"></i> 1% Arena Jackpot
                            <i className="fa fa-fire" aria-hidden="true"></i> 1% Burning
                            <i className="fa fa-wrench" aria-hidden="true"></i> 1% Developer Compensation
                        </div>
                    </div>
                </div>


                <div className="conclusion">
                    This multi-faceted fee structure strikes a balance between player rewards, game development,
                    and token value, making CoinArena a compelling proposition for both players and investors.
                </div>
            </div>
        </div>
    );
}

const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        return (
            <div style={{
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                border: '1px solid #ccd6eb',
                padding: '6px 10px',
                borderRadius: '3px',
                boxShadow: '0px 1px 6px rgba(0,0,0,0.1)',
                fontSize: '14px'
            }}>
                <p style={{ margin: 0, color: '#666666' }}>{`${payload[0].name}: ${payload[0].value}%`}</p>
            </div>
        );
    }
    return null;
};




export default Tokenomics;
